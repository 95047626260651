import React, { useState } from "react";
import styled from "styled-components";

// Components
import { Order } from "./order";

const Table = styled.div`
  padding: 10px 0;
  border-top: 1px solid #09184f;
  border-bottom: 1px solid #09184f;

  & .table-grid {
    display: grid;
    grid-column-gap: 20px;

    &.table-header {
      grid-template-columns: 100px 100px 100px 1fr;

      & .push-right {
        display: block;
        margin: 0 0 0 auto;
      }
    }

    &.four-columns {
      grid-template-columns: 3fr 120px 120px 120px;
    }

    &.three-columns {
      grid-template-columns: 3fr 120px 120px;
    }

    &.two-columns {
      grid-template-columns: 2fr 3fr;

      @media (max-width: 1020px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 600px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }

    & p {
      margin: 0;
    }
  }

  & .mt-2em {
    margin: 2em 0 0 0;
  }

  & .mb-2em {
    margin: 0 0 2em 0;
  }

  & .row {
    & p {
      margin: 0;
    }
  }

  @media (max-width: 600px) {
    & .product-image-container {
      margin: 0 0 2em 0;
    }
  }
`;

export const OrdersList = ({ orders }) => {
  const [selectedOrder, setSelectedOrder] = useState();

  return (
    <>
      <div className="orders-container">
        {orders.edges.length === 0 ? (
          <p className="has-text-grey">You haven't placed any orders yet.</p>
        ) : (
          <>
            {orders.edges.map((order) => (
              <Table
                key={order.node.name}
                className={
                  order.node.name === selectedOrder ? `is-open` : `is-closed`
                }
              >
                <div className="table-grid table-header">
                  <p className="tag uppercase gotham-bold">Order Number</p>
                  <p className="tag uppercase gotham-bold">Order Date</p>
                  <p className="tag uppercase gotham-bold">Total</p>
                </div>

                <div>
                  <div>
                    <div className="table-grid table-header border">
                      <div>
                        <p>{order.node.name}</p>
                      </div>
                      <div>
                        {new Date(order.node.processedAt).toLocaleDateString()}
                      </div>
                      <div>
                        {new Intl.NumberFormat("en-FR", {
                          style: "currency",
                          currency: order.node.currencyCode,
                        }).format(order.node.totalPrice.amount)}
                      </div>
                      <div>
                        <button
                          className="push-right"
                          onClick={() => {
                            if (selectedOrder === order.node.name) {
                              setSelectedOrder(null);
                            } else {
                              setSelectedOrder(order.node.name);
                            }
                          }}
                        >
                          {order.node.name === selectedOrder ? `–` : `+`}
                        </button>
                      </div>
                    </div>

                    {order.node.name === selectedOrder && (
                      <div className="order-details">
                        <Order order={order.node} />
                      </div>
                    )}
                  </div>
                </div>
              </Table>
            ))}
          </>
        )}
      </div>
    </>
  );
};
