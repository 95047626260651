import React from "react";
import styled from "styled-components";

const SingleOrderContainer = styled.div`
  & .shipping-text-container {
    & p {
      margin: 0;
    }
  }
`;

export const Order = ({ order }) => {
  return (
    <SingleOrderContainer>
      <div className="row mt-2em">
        <div className="table">
          <div>
            {order.lineItems &&
              order.lineItems.edges.map((lineItem) => (
                <div
                  key={lineItem.node.title}
                  className="table-grid two-columns mb-2em"
                >
                  <div className="product-image-container">
                    <img
                      src={lineItem.node.variant.image.originalSrc}
                      alt={lineItem.node.variant.image.altText}
                    />
                  </div>

                  <div>
                    <p className="tag uppercase gotham-bold">Product</p>
                    <p className="mb-2em">{lineItem.node.title}</p>
                    <br />

                    <p className="tag uppercase gotham-bold">Quantity</p>
                    <p className="mb-2em">{lineItem.node.quantity}</p>
                    <br />

                    <p className="tag uppercase gotham-bold">Total</p>
                    <p>
                      {new Intl.NumberFormat("en-FR", {
                        style: "currency",
                        currency: lineItem.node.variant.priceV2.currencyCode,
                      }).format(
                        lineItem.node.quantity *
                          lineItem.node.variant.priceV2.amount
                      )}
                    </p>
                  </div>
                </div>
              ))}
            <div className="table-grid four-columns mt-2em">
              <div>
                <p className="tag uppercase gotham-bold">Subtotal</p>
                <p>
                  {new Intl.NumberFormat("en-FR", {
                    style: "currency",
                    currency: order.subtotalPrice.currencyCode,
                  }).format(order.subtotalPrice.amount)}
                </p>
              </div>
            </div>
            <div className="table-grid four-columns mt-2em">
              <div>
                <p className="tag uppercase gotham-bold">Total</p>
                <p>
                  {new Intl.NumberFormat("en-FR", {
                    style: "currency",
                    currency: order.totalPrice.currencyCode,
                  }).format(order.totalPrice.amount)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2em">
        <div>
          <p className="tag uppercase gotham-bold">Shipping Address</p>
        </div>

        <div className="shipping-text-container">
          {order.shippingAddress && (
            <>
              <p>
                {order.shippingAddress.firstName}{" "}
                {order.shippingAddress.lastName}
              </p>
              <p>{order.shippingAddress.address1}</p>
              <p>
                {order.shippingAddress.zip}, {order.shippingAddress.city}
              </p>
              <p>{order.shippingAddress.country}</p>
            </>
          )}
        </div>
      </div>
    </SingleOrderContainer>
  );
};
